<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-spacer class="hidden-sm-and-up" />

    <v-toolbar-title class="mt-16 ml-n4 ml-sm-12">
      <img
        src="logo.png"
        height="75px"
      />
    </v-toolbar-title>

    <v-spacer />

    <div class="mr-12 mt-16 d-none d-sm-flex">
      <v-btn
        min-width="0"
        text
        @click="aboutDialog = true"
      >
        Sobre o site
        <v-icon class="ml-2">mdi-information-outline</v-icon>
      </v-btn>
    </div>

    <div
      class="d-flex d-sm-none"
      style="position: absolute; top: 16px; right: 16px"
    >
      <v-btn
        min-width="0"
        text
        @click="aboutDialog = true"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </div>

    <!-- Process Report Dialog -->
    <v-dialog
      v-model="aboutDialog"
      transition="dialog-top-transition"
      max-width="580px"
      scrollable
    >
      <v-card>
        <v-card-title class="purple darken-3 pb-4">
          <span class="white--text text-h3 font-weight-light">
            SOBRE O SITE
          </span>
        </v-card-title>

        <v-card-text style="height: 200px">
          <v-container class="mt-6">
            <v-row class="mb-4">
              <v-col
                cols="12"
                sm="4"
                class="text-sm-right text-body-1 text-uppercase"
              >O site</v-col>
              <v-col
                cols="12"
                sm="8"
                class="text-body-1"
                style="border-left: solid #515151 1px"
              >
                <p>Site com a situação atual e evolução dos casos de COVID-19 em Pirassununga. Contém gráficos do número de novos casos e de internações por dia desde o início da pandemia.</p>
                <p>Este é um projeto independente, não oficial, criado com o intuito pessoal de prática de desenvolvimento de software, mais precisamente nas técnicas de web scrapping e análise de dados.</p>
                <p>Os dados aqui apresentados são obtidos de maneira automatizada a partir do site oficial da Prefeitura Municipal de Pirassununga. Tento fazer meu melhor, porém estes dados podem conter erros. Caso encontre algum erro, por favor, informe ao desenvolvedor pelos contatos abaixo apresentados.</p>
                <p>O conteúdo deste site é livre para reprodução, sem necessidade de autorização prévia e, sem necessidade de informar a fonte. Se puder indicar o site como fonte, agradeço.</p>
                <p>As responsabilidades das interpretações e utilizações destes dados são por conta e risco de quem os utilizar.</p>
                <p>Juntos somos mais fortes :)</p>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col
                cols="12"
                sm="4"
                class="text-sm-right text-body-1 text-uppercase"
              >Desenvolvedor</v-col>
              <v-col
                cols="12"
                sm="8"
                class="text-body-1"
                style="border-left: solid #515151 1px"
              >
                Thiago Marquesini<br />
                <a
                  @click="openExternalLink('mailto:contato@marquesini.com.br', 'link_author_mail')"
                  class="text-decoration-none"
                >contato@marquesini.com.br</a><br />
                <a
                  @click="openExternalLink('tel:+5519996315665', 'link_author_phone')"
                  class="text-decoration-none"
                >19 99631-5665</a>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col
                cols="12"
                sm="4"
                class="text-sm-right text-body-1 text-uppercase"
              >Tecnologias utilizadas</v-col>
              <v-col
                cols="12"
                sm="8"
                class="text-body-1"
                style="border-left: solid #515151 1px"
              >
                VueJS<br />
                ChartJS<br />
                Firebase Function<br />
                Firebase Firestore<br />
                Firebase Hosting
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2 py-4">
          <v-spacer />
          <v-btn
            color="grey darken-3"
            @click="aboutDialog = false"
            rounded
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'DashboardCoreAppBar',

    data () {
      return {
        aboutDialog: false,
      }
    },

    methods: {
      showAboutDialog () {
        this.aboutDialog = true
        this.$firebase.analytics().logEvent('page_view', 'about')
      },

      openExternalLink (href, event) {
        this.$firebase.analytics().logEvent('link', event)
        window.open(href, '_blank')
      },
    },
  }
</script>
